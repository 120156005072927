import request from "@/utils/request"

export default {
    changePassword(data) {
        return request({
            url: '/v1/adminChangePassword',
            method: 'post',
            data
        })
    }
}
