<template>
  <div id="app">
    <div class="mine">
      <div class="bg-top">
        <div class="mine-title">个人中心</div>
        <div class="mine-avatar">
          <img :src="avatar" alt="头像" />
        </div>
        <div class="mine-name">{{ name }}</div>
        <div class="mine-job">{{ jName }}</div>
      </div>
      <div class="cells">
        <van-cell is-link size="large" @click="goUrls('/knowledge/knowledge')">
          <template #title>
            <span class="cell-img"><img src="../../assets/mine-doc.png" alt=""></span>
            <span class="cell-title">文档查询</span>
          </template>
        </van-cell>
        <van-cell is-link size="large" @click="passwordShow = true">
          <template #title>
            <span class="cell-img"><img src="../../assets/mine-change-pwd.png" alt=""></span>
            <span class="cell-title">修改密码</span>
          </template>
        </van-cell>
        <van-cell is-link size="large">
          <template #title>
            <span class="cell-img"><img src="../../assets/mine-about-us.png" alt=""></span>
            <span class="cell-title">关于我们</span>
          </template>
        </van-cell>
        <div class="cell">
          <van-cell
              title="退出登录"
              title-class="cell-title"
              icon="manager-o"
              is-link
              @click="logout"
          />
        </div>
      </div>
    </div>
    <van-overlay :show="passwordShow">
      <div class="change-password">
        <van-form @submit="onPwSubmit">
          <van-field
            v-model="data.password1"
            type="password"
            name="密码"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
          <van-field
            v-model="data.password2"
            type="password"
            name="确认密码"
            label="确认密码"
            placeholder="确认密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
          <div class="submit">
            <div class="submit-button">
              <van-button round block type="primary" native-type="submit">提交</van-button>
            </div>
            <van-button round block type="default" @click="cancelSubmit">取消</van-button>
          </div>
        </van-form>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import API from "@/api/personnel/staff"
import { mapGetters } from "vuex"
import { Notify } from 'vant'
export default {
  inject: ["reload", "goUrl"],
  data() {
    return {
      loadingPicker: false,
      picker: "",

      task: "",
      data: {
        password1: "",
        password2: "",
      },
      passwordShow: false,
    };
  },
  computed: {
    ...mapGetters(['name', 'avatar', 'userId', 'jName']),
  },
  created() {
    this.index();
  },
  methods: {
    index() {
    },
    onPwSubmit() {
      const p1 = this.data.password1
      const p2 = this.data.password2
      if (p1.length < 6) {
        Notify({
          type:'danger',
          message: '密码长度必须大于6位！'
        })
        return false;
      } else if (p1 !== p2) {
        Notify({
          type: 'danger',
          message: '两次输入的密码不一样！'
        })
        return false;
      } else {
        const password_data = {
          p1,
          p2,
          id: this.userId,
        }
        API.changePassword(password_data).then((res) => {
          if (res.data.error_code === 1) {
            Notify({
              type: 'success',
              message: '更改成功'
            })
            this.passwordShow = false
            this.data.password1 = ''
            this.data.password2 = ''
          } else {
            Notify({ type: 'danger', message: res.data.message })
          }
        })
      }
    },
    cancelSubmit() {
      this.data.password1 = ''
      this.data.password2 = ''
      this.passwordShow = false
    },
    goUrls(url) {
      this.goUrl(url)
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      await this.$router.push('/login')
    }
  },
};
</script>

<style scoped>
.bg-top {
  width: 375px;
  height: 212px;
  background-image: url('../../assets/mine-bg.png');
  background-size: 100% 100%;
  padding-top: 33px;
  box-sizing: border-box;
  color: #fff;
}
.bg-top .mine-title {
  font-size: 17px;
}
.bg-top .mine-avatar {
  width: 62px;
  height: 62px;
  margin: 24px auto 8px;
  border-radius: 50%;
  overflow: hidden;
}
.bg-top .mine-avatar img {
  width: 100%;
}
.bg-top .mine-name {
  font-size: 15px;
}
.bg-top .mine-job {
  font-size: 12px;
  margin-top: 10px;
}
.cells {
}
.cells > div {
  text-align: left;
}
.cell-img {
  width: 17px;
  height: 17px;
  margin-right: 10px;
  display: block;
  float: left;
  padding-top: 3px;
}
.cell-img img{
  width: 100%;
  display: block;
}
.cell-title {
  font-size: 15px;
}
.change-password {
  width: 80%;
  margin: 130px auto;
}
.change-password .submit-button {
  margin-bottom: 10px !important;
}
.change-password .van-cell {
  margin-bottom: 20px;
}
</style>
